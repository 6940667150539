import logo from "./commingsoon.jpg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header
        className="App-header"
        style={{
          backgroundImage: `url(${logo})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100%",
        }}
      >
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
      </header>
    </div>
  );
}

export default App;
